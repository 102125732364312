import React, { useCallback, useMemo, useState } from 'react'
import {
  Button,
  SpinnerToggle,
  useDirectoryV3ManifestSet,
  useShowError,
} from '@aserto/console-common'

import { useAcceptInvite, useAccountInvites } from '../../../api/members'
import { useGetManifest, useListOnboardingTemplates } from '../../../api/onboarding'
import { useOnboardingContext } from '../../../services/OnboardingContextProvider'
import { useProfile } from '../../../services/ProfileAndQueryClientProvider'
import { AccountTenantInvite } from '../../../types/local/tenant'
import { isInviteAcceptable } from '../../../utils/invites'
import {
  AllContent,
  ButtonContainer,
  CenteredContent,
  InvitationsContainer,
  InviteCard,
  ModalDescription,
} from '../styles'

type MappedInvite = {
  id: string | undefined
  tenant: string | undefined
  invited_by: string | undefined
  invited_date: string
  tenant_id: string | undefined
}

export const Invitations = () => {
  const { activeStep, setActiveStep, clearSteps } = useOnboardingContext()
  const { data: accountInvitesData, isLoading: isLoadingInvites } = useAccountInvites()
  const { setTenantId } = useProfile()
  const [loading, setLoading] = useState<boolean>(false)
  const showError = useShowError()
  const { data: templates } = useListOnboardingTemplates({
    legacy: true,
  })
  const defaultTemplate = templates?.results?.filter((template) => template.id === 'todo')?.[0]

  const { data: manifestData } = useGetManifest(defaultTemplate?.manifest || '')
  const { mutate: setManifest } = useDirectoryV3ManifestSet()

  const invites: Array<AccountTenantInvite> = useMemo(
    () => accountInvitesData?.results || [],
    [accountInvitesData?.results]
  )

  const invitesToDisplay: MappedInvite[] = useMemo(
    () =>
      invites.filter(isInviteAcceptable).map((invite: AccountTenantInvite): MappedInvite => {
        return {
          id: invite?.invite?.id,
          tenant: invite?.tenant?.name,
          invited_by: invite?.invite?.invited_by,
          invited_date: new Date(String(invite?.invite?.created_at)).toDateString(),
          tenant_id: invite?.tenant?.id,
        }
      }),
    [invites]
  )

  const { mutateAsync: acceptInviteMutation } = useAcceptInvite()

  const acceptInvite = useCallback(
    async (id: string, tenantId: string) => {
      setLoading(true)
      try {
        !!manifestData && setManifest({ data: manifestData })
        await acceptInviteMutation({ inviteId: id, tenantId })
        clearSteps()
        setTenantId(tenantId)
      } catch (error) {
        showError(error)
      } finally {
        setLoading(false)
      }
    },
    [acceptInviteMutation, showError, setTenantId, clearSteps, manifestData, setManifest]
  )

  if (isLoadingInvites || loading) {
    return <SpinnerToggle />
  }

  return (
    <>
      <AllContent>
        <CenteredContent>
          <ModalDescription>
            If you accept an invitation, you'll immediately join that organization instead of
            completing the onboarding. If you'd like to complete the onboarding first, you can skip
            this step. After the onboarding, you can accept or decline invitations through the
            Account Settings page.
          </ModalDescription>
          <InvitationsContainer>
            {invitesToDisplay?.map((t, i) => (
              <InviteCard key={i}>
                <div>
                  <p className="title">Organization: {t.tenant}</p>
                  <p className="desc">Date invited: {`${t.invited_date}`}</p>
                </div>
                <Button
                  variant="primary"
                  onClick={() => {
                    acceptInvite(String(t.id), String(t.tenant_id))
                  }}
                >
                  Accept
                </Button>
              </InviteCard>
            ))}
          </InvitationsContainer>
          <ButtonContainer>
            <Button
              variant="secondary"
              onClick={() => {
                setActiveStep(activeStep + 1)
              }}
            >
              Skip
            </Button>
          </ButtonContainer>
        </CenteredContent>
      </AllContent>
    </>
  )
}
