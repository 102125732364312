import { useMutation, useQuery, UseQueryOptions } from 'react-query'
import { AccountNameValidator, useAsertoApiWithAuth, useIdentity } from '@aserto/console-common'

import { useConsoleBackendClient } from '../clients/ConsoleBackendClient'
import { useHubSpotClient } from '../clients/HubSpotClient'
import { useOnboardingClient } from '../clients/OnboardingClient'
import { ListOnboardingTemplatesResponse } from '../types/local/consoleBackend'
import {
  MutationKeys,
  QueryKeys,
  reactQueryDefaultOptions,
  ReactQueryMutationHooks,
  useMutationOptions,
} from '../types/local/general'
import {
  ClaimTenantRequestBody,
  ClaimTenantResponse,
  RpcStatus,
  TenantAvailableResponse,
} from '../types/local/tenant'

export const useValidateTenantName = (accountName: string) => {
  const { get } = useOnboardingClient()
  return useQuery(
    [QueryKeys.CreateAccountNameValidation, accountName],
    () => {
      return AccountNameValidator.isValid(accountName)
        ? get<TenantAvailableResponse>({ path: `available/${accountName}` })
        : Promise.resolve<TenantAvailableResponse>({
            availability: 'TENANT_AVAILABILITY_INVALID',
            reason: 'Failed local validity check',
          })
    },
    reactQueryDefaultOptions
  )
}

export const useClaimTenant = (
  mutationHooks: ReactQueryMutationHooks<ClaimTenantResponse, ClaimTenantRequestBody> = {}
) => {
  const { hubspotCreateContact, hubspotSetProperty } = useHubSpotClient()
  const { user } = useIdentity()

  const mutationOptions = useMutationOptions({
    ...mutationHooks,
    onSuccess: async (result, { personal, name }, context) => {
      const operation = personal ? 'created_account' : 'created_organization'

      // ensure the current user is a contact before setting the property
      try {
        if (personal && user?.email) {
          await hubspotCreateContact({
            email: user.email,
          })
        }
      } catch {}
      try {
        await hubspotSetProperty({
          name: operation,
          value: name,
        })
      } catch {}
      mutationHooks.onSuccess?.(result, { personal, name }, context)
    },
  })

  const { post } = useConsoleBackendClient()
  return useMutation(
    MutationKeys.ClaimTenant,
    (body: ClaimTenantRequestBody) =>
      post<ClaimTenantResponse, ClaimTenantRequestBody>({ body, path: `onboarding/claim` }),
    mutationOptions
  )
}

export const useListOnboardingTemplates = (
  options?: Omit<
    UseQueryOptions<
      ListOnboardingTemplatesResponse,
      RpcStatus,
      ListOnboardingTemplatesResponse,
      QueryKeys[]
    >,
    'queryKey' | 'queryFn' | 'retry' | 'staleTime'
  > & {
    legacy?: boolean
  }
) => {
  const { get } = useConsoleBackendClient()
  const response = useQuery(
    [
      QueryKeys.ListOnboardingTemplates,
      { legacy: options?.legacy || false } as unknown as QueryKeys,
    ],
    () =>
      get<ListOnboardingTemplatesResponse>({
        path: `onboarding-templates`,
        queryParams: {
          legacy: options?.legacy || false,
        },
      }),
    {
      ...options,
      enabled: options?.enabled,
      retry: false,
      staleTime: Infinity,
    }
  )

  return response
}

export const useGetManifest = (url: string, options?: { enabled?: boolean }) => {
  const { getBlob } = useAsertoApiWithAuth(url)
  return useQuery([url], () => getBlob<Blob>({ path: '' }), {
    enabled: !!url && options?.enabled,
    retry: false,
    staleTime: Infinity,
  })
}
