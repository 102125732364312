import React, { useState } from 'react'
import { Button } from '@aserto/console-common'

import auth0 from '../../../assets/idps/auth0.svg'
import aws from '../../../assets/idps/aws.svg'
import azure from '../../../assets/idps/azure.svg'
import googlecloud from '../../../assets/idps/google-cloud.svg'
import group from '../../../assets/idps/group.svg'
import iantien from '../../../assets/idps/Ian Tien.svg'
import karenberg from '../../../assets/idps/Karen Berg.svg'
import morty from '../../../assets/idps/morty.svg'
import okta from '../../../assets/idps/okta.svg'
import rick from '../../../assets/idps/rick.svg'
import { useOnboardingContext } from '../../../services/OnboardingContextProvider'
import { useProfile } from '../../../services/ProfileAndQueryClientProvider'
import { ProviderId } from '../../../types/local/hardcodedBackendConstants'
import { useHasExistingConnection } from '../../../utils/connections'
import AddConnectionModal from '../../connections/AddConnectionModal'
import {
  AllContent,
  ButtonContainer,
  CenteredContent,
  IdpCard,
  IdpsDiv,
  ModalDescription,
} from '../styles'

const ConnectIdp = () => {
  const { selectedTemplate, onboardingTenantId } = useOnboardingContext()
  const { setTenantId } = useProfile()
  const { setFadeBackground, clearSteps } = useOnboardingContext()
  const [providerId, setSelectedProviderId] = useState<string>()
  const acmecorpConnected = useHasExistingConnection(ProviderId.IDPAcmeCorpLegacy)
  const citadelConnected = useHasExistingConnection(ProviderId.IDPCitadelLegacy)
  const defaultConnection = selectedTemplate?.connection?.provider_id

  const selectedIdpCard = () => {
    if (defaultConnection === ProviderId.IDPCitadelLegacy) {
      return (
        <IdpCard>
          <div>
            <span className="idp-title">Citadel IDP</span>
            {!!citadelConnected && <span className="connected">Connected</span>}
          </div>
          <span className="idp-desc">
            This demo IDP includes a few users and groups, modeled after Rick & Morty.
          </span>
          <div className="default-idp-icons">
            <img alt="rick-citadel" src={rick} />
            <img alt="morty-citadel" src={morty} />
          </div>
        </IdpCard>
      )
    }

    if (defaultConnection === ProviderId.IDPAcmeCorpLegacy) {
      return (
        <IdpCard>
          <div>
            <span className="idp-title">Acmecorp IDP</span>
            {!!acmecorpConnected && <span className="connected">Connected</span>}
          </div>
          <span className="idp-desc">
            A sample corporate directory including 270 users, 20 groups, and management
            relationships.
          </span>
          <div className="default-idp-icons">
            <img alt="Karen Berg" src={karenberg} />
            <img alt="Ian Tien" src={iantien} />
            <img alt="group" src={group} />
          </div>
        </IdpCard>
      )
    }
  }
  const [showAddConnectionModal, setShowAddConnectionModal] = useState(false)
  const onClickAddConnection = (providerId: string) => {
    setSelectedProviderId(providerId)
    setFadeBackground(true)
    setShowAddConnectionModal(true)
  }

  return (
    <>
      <AddConnectionModal
        oAuthRedirectUrl="/"
        providerFilter={(p) => p.id === providerId}
        providerKind="IDENTITY_PROVIDER"
        show={showAddConnectionModal}
        onHide={() => {
          setFadeBackground(false)
          setShowAddConnectionModal(false)
        }}
        onSuccess={() => {
          setTenantId(onboardingTenantId)
          clearSteps()
        }}
      />
      <AllContent>
        <CenteredContent>
          <ModalDescription>
            The template you selected already connected a sample IDP for you, containing some test
            users, groups, and relationships. You can disconnect it later if you don't want that
            data.
            <br />
            <br />
            You can connect your own IDP by selecting it below, or you can do it later in the
            Connections page.
          </ModalDescription>
          <IdpsDiv>
            {selectedIdpCard()}
            <IdpCard>
              <span className="idp-title">Connect your own</span>
              <div className="idp-icons">
                <img
                  alt="auth0"
                  src={auth0}
                  onClick={() => {
                    onClickAddConnection(ProviderId.IDPAuth0)
                  }}
                />
                <img
                  alt="okta"
                  src={okta}
                  onClick={() => {
                    onClickAddConnection(ProviderId.IDPOkta)
                  }}
                />
                <img
                  alt="aws"
                  src={aws}
                  onClick={() => {
                    onClickAddConnection(ProviderId.IDPCognito)
                  }}
                />
                <img
                  alt="azure"
                  src={azure}
                  onClick={() => {
                    onClickAddConnection(ProviderId.IDPAzureAD)
                  }}
                />
                <img
                  alt="google-cloud"
                  src={googlecloud}
                  onClick={() => {
                    onClickAddConnection(ProviderId.IDPGoogleWorkspace)
                  }}
                />
              </div>
            </IdpCard>
          </IdpsDiv>
          <ButtonContainer>
            <Button
              variant="secondary"
              onClick={() => {
                setTenantId(onboardingTenantId)
                clearSteps()
              }}
            >
              Continue
            </Button>
          </ButtonContainer>
        </CenteredContent>
      </AllContent>
    </>
  )
}

export default ConnectIdp
